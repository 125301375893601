// import configureStore from "./../../state/store";
// import { loadState } from "../helpers/stateHelper";
//
// const initial_data = loadState();
// const state = configureStore(initial_data).getState();

export const APP_VERSION = '1.8.38';
export const BUILD_NUMBER = 20250121;

export const BASE_URL = 'https://cashier.gdbusiness.com/';
export const API_BASE_URL = window.location.host === 'cashier.gd-business.com' ? 'https://api.getdiskon.com/' : 'https://staging.api.gdbusiness.id/';
export const IMAGE_BASE_URL = 'https://getdiskon.com/';

export const NORMAL_TIMEOUT_MS = 30000;
export const CUSTOMER_SEARCH_TIMEOUT = 10000;

export const MONTH_NAMES_LONG = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const MONTH_NAMES_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
export const MOBILE_DATEPICKER_MONTH_LONG_MAP = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
};

export const MOMENT_READABLE_DATETIME_FORMAT = 'D MMM YYYY, HH:mm';
export const MOMENT_READABLE_DATE_FORMAT = 'D MMM YYYY';
export const MOMENT_SQL_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_SQL_TIME_FORMAT = 'HH:mm:ss';
export const MOMENT_SQL_TIME_FORMAT_MINUTE = 'HH:mm';
export const MOMENT_SQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const TRX_TYPE_NORMAL = 'NORMAL';
export const TRX_TYPE_COUPON_SALES = 'COUPON_SALES';
export const TRX_TYPE_TOP_UP_DEPOSIT = 'TOP_UP_DEPOSIT';
export const TRX_TYPE_MEMBERSHIP_SALES = 'MEMBERSHIP_SALES';
export const TRX_TYPE_ACCOUNT_RECEIVABLE = 'ACCOUNT_RECEIVABLE';

export const PAYMENT_TYPE_NORMAL = 'NORMAL';
export const PAYMENT_TYPE_DEPOSIT = 'DEPOSIT';
export const PAYMENT_TYPE_CREDIT = 'CREDIT';

export const INV_SRC_TYPE_FROM_SUPPLIER = 'FROM_SUPPLIER';
export const INV_SRC_TYPE_FROM_BAD_STOCK = 'FROM_BAD_STOCK';
export const INV_SRC_TYPE_TO_BAD_STOCK = 'TO_BAD_STOCK';
export const INV_SRC_TYPE_CONSUMPTION = 'CONSUMPTION';
export const INV_ADJ_TYPE_FINAL = 'FINAL';
export const INV_ADJ_TYPE_INCREASE = 'INCREASE';
export const INV_ADJ_TYPE_DECREASE = 'DECREASE';
export const INV_CONSUMPTION_TYPE_GENERAL = 'GENERAL';
export const INV_CONSUMPTION_TYPE_FOR_CUSTOMER = 'FOR_CUSTOMER';

export const RECEIPT_TEMPLATE_DEFAULT = 'DEFAULT';
export const RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END = 'GROUP_DISCOUNT_AT_THE_END';


export const DEFAULT_CURRENCY_SIGN = 'IDR';
export const DEFAULT_DECIMAL_SEPARATOR = '.';
export const DEFAULT_THOUSAND_SEPARATOR = ',';
export const DEFAULT_PRICE_DECIMAL_LENGTH = 2;
export const DEFAULT_QTY_DECIMAL_LENGTH = 2;
export const ROOF_INT_VAL = 999999999;
