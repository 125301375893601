import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Icon, Input, message, Row, Spin, Table } from "antd";
import { apiCall, asyncApiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./AccountReceivableList.module.scss";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT, TRX_TYPE_ACCOUNT_RECEIVABLE } from "../shared/utils/constants";
import connect from "react-redux/es/connect/connect";
import { number_format } from "../shared/helpers/stringHelper";
import moment from "moment";
import { posOperations } from "../state/ducks/pos";
import Highlighter from "react-highlight-words";
import { empty } from "../shared/helpers/generalHelper";

class AccountReceivableList extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            account_receivables: [],
            ARData: []
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        this.loadAccountReceivables();
    }

    loadAccountReceivables = () => {
        this.setState({ formLoading: true });

        apiCall(
            endpoint.GET_ACCOUNT_RECEIVABLE_LIST,
            "get",
            null,
            result => {
                this.setState(
                    {
                        account_receivables: result.data
                    },
                    () => {
                        this.setState({ formLoading: false });
                    }
                );
            },
            error => {
                if (error === "timeout") {
                    // Network is not available
                    message.error(
                        "Telah terjadi kegagalan koneksi, silakan coba lagi."
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ formLoading: false });
            }
        );
    };

    customFilterProps = (dataIndex, dataLabel, customRender = null) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataLabel}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => {
            let modifiedText = text || '';
            if(!empty(customRender)){
                modifiedText = customRender(text);
            }
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={modifiedText}
                />
            );
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getARData = async (userCode) => {
        this.setState({ formLoading: true });

        await asyncApiCall(
            `/cashier/user-account-receivables?user_id=${userCode}`,
            "get",
            null,
            result => this.setState({ ARData: result.data ?? [] }),
            error => {
                if (error === "timeout") {
                    // Network is not available
                    message.error(
                        "Telah terjadi kegagalan koneksi, silakan coba lagi."
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ formLoading: false });
            },
            () => this.setState({ formLoading: false })
        );
    }

    render() {
        const {
            formLoading, account_receivables
        } = this.state;

        const accountReceivableColumns = [
            {
                title: "Nama Pelanggan",
                dataIndex: "user_name",
                key: "user_name",
                sorter: (a, b) => a.user_name.length - b.user_name.length,
                ...this.customFilterProps('user_name', 'Nama Pelanggan')
            },
            {
                title: "No Transaksi",
                dataIndex: "trx_id",
                key: "trx_id",
                sorter: (a, b) => a.trx_id.length - b.trx_id.length,
                ...this.customFilterProps('trx_id', 'No Transaksi')
            },
            {
                title: "Tanggal Transaksi",
                dataIndex: "trx_time",
                key: "trx_time",
                sorter: (a, b) => a.trx_time.length - b.trx_time.length,
                ...this.customFilterProps('trx_time', 'Tanggal Transaksi', (text) => {
                    return moment(text, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)
                })
            },
            {
                title: "Jumlah Piutang",
                dataIndex: "drv_balance",
                key: "drv_balance",
                sorter: (a, b) => a.drv_balance.length - b.drv_balance.length,
                ...this.customFilterProps('drv_balance', 'Jumlah Piutang', (text) => {
                    return `${this.currencyCode} ${number_format(text)}`;
                })
            },
            {
                title: "Outlet",
                dataIndex: "outlet_name",
                key: "outlet_name",
                sorter: (a, b) => a.outlet_name.length - b.outlet_name.length,
                ...this.customFilterProps('outlet_name', 'Outlet')
            },
            {
                title: "Referensi",
                dataIndex: "payment_method_reference",
                key: "payment_method_reference",
                sorter: (a, b) => a.payment_method_reference.length - b.payment_method_reference.length,
                ...this.customFilterProps('payment_method_reference', 'Referensi')
            },
            {
                title: "Action",
                key: "action",
                render: (text, record) => (
                    <span>
                        <Button size="small"
                                type="primary"
                                htmlType="button"
                                onClick={async () => {
                                    const transactionName = "Pembayaran Piutang";
                                    await this.getARData(record.user.user_code);
                                    this.props.setNewLocalTrx({
                                        customer: {
                                            ...record.user,
                                            user_gender: record.user.user_gender === 1 ? "Male" : "Female",
                                            user_identifier_type: "qr",
                                            user_identifier_string: record.user.user_code,
                                            account_receivables: this.state.ARData
                                        },
                                        trx_type: TRX_TYPE_ACCOUNT_RECEIVABLE,
                                        trx_name: transactionName,
                                    });

                                    this.props.history.push("/pos?next=true");
                                }}>Bayar</Button>
                    </span>
                )
            }
        ];

        const pageTitle = (
            <React.Fragment>
                <Icon type="transaction"/>
                &nbsp;&nbsp; Daftar Piutang
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={18} sm={24}>
                        <Spin spinning={formLoading}>
                            <div className={styles.formItem}>
                                <Table rowKey="trx_id" columns={accountReceivableColumns} dataSource={account_receivables} pagination={false}/>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setNewLocalTrx: posOperations.setNewLocalTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountReceivableList);